import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const Regiones = (props) => {
  const options = props.lRegiones.map((d) => ({
    'value': d.region_id,
    'label': d.nombre,
  }));

  const changeRegion = props.changeRegion;
  let setSelecteRegion = props.setSelecteRegion;
  const detalleRegion = props.detalleRegion;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleRegion != undefined) {
      const arr = detalleRegion.map((d) => ({
        'value': d.region_id,
        'label': d.region.nombre,
        'cf_idRegion': d.region.cf_idRegion,
      }));
      setValueDetalle(arr);
      setSelecteRegion(arr);

      if (props.disabled) {
        setDisable(true);
      }
      
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {

    changeRegion(e);
    setSelecteRegion(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="region">Region</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="region"
           value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))} 
            isDisabled={disable}
             />
        </div>
      </div>
    </>
  );
};

export default Regiones;
