import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const TipoReceta = (props) => {
  const options = props.lTipoReceta.map((d) => ({
    'value': d.tipoReceta_id,
    'label': d.descripcion,
    'cf_RecipeTypeId': d.cf_RecipeTypeId,
  }));

  const changeTipoReceta = props.changeTipoReceta;
  const setSelecteTipoReceta = props.setSelecteTipoReceta;
  const detalleTipoReceta = props.detalleTipoReceta;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleTipoReceta != undefined) {
      const arr = detalleTipoReceta.map((d) => ({
        'value': d.tipoReceta_id,
        'label': d.tipo_receta.descripcion,
        'cf_RecipeTypeId': d.tipo_receta.cf_RecipeTypeId,
      }));
  
      setValueDetalle(arr);
      setSelecteTipoReceta(arr);

      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    changeTipoReceta(e);
    setSelecteTipoReceta(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="tipoEncuesta">Tipo de Receta</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="tipoReceta"
           value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))} 
            isDisabled={disable}
             />
        </div>
      </div>
    </>
  );
};

export default TipoReceta;
