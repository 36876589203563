import React, { useState, useEffect } from 'react';

const productos = (props) => {



const onchangeOptions = (e)=>{
  console.log(e);
}

  return (
    <>
      {
         props.currentItems && props.currentItems.map((productos) => (
              <tr key={productos.RecipeId}>
              <td>{productos.Description}</td>
              <td>{
                
              productos.Active ? "SI" : "NO"
              
              }</td>
            </tr>
          ))
          }

    </>
  );

}

export default productos;