import React from "react";
import { Link } from "react-router-dom";
import { Encrypt } from "../Utils/EncryptDecrypt";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";


const Sidebar = ({ guardarPagina }) => {
  const cambiarPagina = (pagina) => {
    guardarPagina(pagina);
    Encrypt("pagina", pagina);
  };
  return (
    <>
      <aside>
        <h1 className="sidebar-titulo">
          <img id="logoBar" src="https://caffenio.com/img/logos/logo_caffenio.svg"></img>
        </h1>
        <div className="sidebar-contenedor-opciones">
          <a href="#!" onClick={() => cambiarPagina("home")}>
            <div className="sidebar-opcion">
              <span className="material-icons">dashboard</span>
              <label htmlFor="" className="sidebar-label">
                Dashboard
              </label>
              
            </div>
          </a>
          {/*
          <a href="#!" onClick={() => cambiarPagina("administracion")}>
            <div className="sidebar-opcion">
              <span className="material-icons">people</span>
              <label htmlFor="" className="sidebar-label">
                Usuarios
              </label>
            </div>
          </a>*/
          }
          <a href="#!" onClick={() => cambiarPagina("formularios")}>
            <div className="sidebar-opcion">
              <span className="material-icons">feed</span>
              <label htmlFor="" className="sidebar-label">
               Encuestas en ticket de compra
              </label>
            </div>
          </a>
          <a href="#!" onClick={() => cambiarPagina("addformulario")}>
            <div className="sidebar-opcion">
              <span className="material-icons">feed</span>
              <label htmlFor="" className="sidebar-label">
                Agregar Formulario
              </label>
            </div>
          </a>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
