import React, { useEffect,useState } from "react";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import Layout from "../layout/Layout";
import AddForm from "./Add-Formulario";
import DetForm from "./Det-Formulario";
import UpForm from "./Up-Formulario";
import Formularios from "./Formulario";
const Home = (props) => {
  //console.log(props);
  const [pagina, guardarPagina] = useState("");
  const [detalleEncuesta, setDetalleEncuesta] = useState([]);
  //console.log(props);
  useEffect(() => {
    guardarPagina(Decrypt("pagina"));
  }, [pagina]);

  useEffect(() => {
    guardarPagina(Decrypt("pagina"));
  }, [detalleEncuesta]);
  
  return (
    <>
      <Layout guardarPagina={guardarPagina}>
        {pagina == "home" && <div>Bienvenido</div>}
        {pagina == "formularios" && (<Formularios guardarPagina={guardarPagina} setDetalleEncuesta={setDetalleEncuesta}/>)}
        {pagina == "addformulario" && (<AddForm guardarPagina={guardarPagina} />)}
        {pagina == "detformulario" && (<DetForm guardarPagina={guardarPagina}  detalleEncuesta={detalleEncuesta}/>)}
        {pagina == "upformulario" && (<UpForm guardarPagina={guardarPagina}  detalleEncuesta={detalleEncuesta}/>)}
      </Layout>
    </>
  );
};

export default Home;
