import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const Plazas = (props) => {
  const options = props.lPlazas.map((d) => ({
    'value': d.plaza_id,
    'label': d.nombre,
  }));

  const changePlaza = props.changePlaza;
  const setSelectePlaza = props.setSelectePlaza;
  const detallePlaza = props.detallePlaza;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detallePlaza != undefined) {
      const arr = detallePlaza.map((d) => ({
        'value': d.plaza_id,
        'label': d.plaza.nombre,
        'cf_idPlaza': d.plaza.cf_idPlaza,
      }));
  
      setValueDetalle(arr);
      setSelectePlaza(arr);

      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    changePlaza(e);
    setSelectePlaza(e);
    setValueDetalle(e);
  };

  return (
    <>
    <div className="col-md-3">
<div className="form-group">
<label htmlFor="plaza">Plaza</label>
      <Select options={options} isMulti onChange={onchangeOptions} id="plaza"
      value={
        valueDetalle.map((a)=>({
         value:a.value,
         label:a.label
        }))} 
        isDisabled={disable}
         />
</div>
</div>
    </>
  );
};

export default Plazas;
