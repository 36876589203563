import React, { useState, useEffect } from 'react';
import Select from 'react-select';

let options = [
  { value: 'Hola', label: 'Hola' },
  { value: 'Amigo ', label: 'Amigo ' },
  { value: 'Fan', label: 'Fan' },
]



const ClienteSin = (props) => {

  const detalleTipoCliente = props.detalleTipoCliente;
  
  const tipoClienteValueNivel = props.setTipo_ClienteNivel;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleTipoCliente != undefined) {

      const arr = detalleTipoCliente.map((d) => ({
        'value': d.nivel,
        'label': d.nivel,
      }));
      setValueDetalle(arr);
      tipoClienteValueNivel(arr);

      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    tipoClienteValueNivel(e);
    setValueDetalle(e);
  };
 
  return (
    <>
      <Select options={options} id="nivelCliente" 
      isMulti
      name="nivelCliente" 
      onChange={onchangeOptions} 
      value={
        valueDetalle.map((a)=>({
         value:a.value,
         label:a.label
        }))} 
        isDisabled={disable}     
      >
        
      </Select>
    </>
  );
};

export default ClienteSin;
