import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './css/Encuesta.css';

const useStyles = makeStyles({
  root: {
    width: '50%',
    margin: '6rem auto',
    border: '1px solid #999',
    '& .MuiStepIcon-root.MuiStepIcon-active': { color: 'red' },
  },
});

const Encuesta = (props) => {


  const [actualizar, setActualizar] = useState(false);
  const [script, setScript] = useState('');
  const classes = useStyles();
  
  useEffect(() => {
    //setActualizar(false);
    cargarScript();
    },[actualizar]);

  async function cargarScript(){
    //console.log(script);
    console.log(props);
    //const options = props.location.params.script_encuesta;
    const options ='(function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="text/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdy_2Bu7qT4z8QY5KUHAnzBh6_2FkNqryHvG7V3nZaDHS_2B_2Fwg.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");';
    
    if (options!=undefined) {
      setScript(options);
    }
  
    console.log("script");
    console.log(script);

    const url = script;
    let htmlScript = document.getElementById('smcx-sdk');
    const elemento = document.createElement('script');
    const container = document.getElementById('formulario');
    let divClass = document.getElementsByClassName(
      'smcx-widget smcx-embed smcx-show smcx-widget-dark smcx-hide-branding'
    )[0];
    elemento.innerText = url;
    container.append(elemento);
   await setTimeout(() => {
      divClass = document.getElementsByClassName(
        'smcx-widget smcx-embed smcx-show smcx-widget-dark smcx-hide-branding'
      )[0];
      htmlScript = document.getElementById('smcx-sdk');
      container.append(divClass);
     // document.getElementsByClassName('smcx-iframe-container')[0].style.height = '700px';
    }, 2000);

    setActualizar(true);
  
  }

  
  const OnSubmitForm = (e) => {



  };
  
  return (
    <>
      <div className="big-image">
        <div className="overlay">
          <div className={classes.root}>
            <div id="formulario"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Encuesta;
