import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css, cx } from '@emotion/css';
import clienteAxios from '../Utils/axios';
import { Encrypt, Decrypt } from '../Utils/EncryptDecrypt';
import Home from '../pages/Home';
import './Login.css';

const Login = (props) => {
  const [login, guardarLogin] = useState({
    usuario: '',
    password: '',
  });
  const [logueado, guardarLogueado] = useState(false);
  const [pagina, guardarPagina] = useState('');

  useEffect(() => {
    // console.log('DT', Decrypt('token'));
    if (Decrypt('token') != null) {
      guardarLogueado(true);
    }
  }, []);

  // console.log("Logueado", logueado);

  const onChange = (e) => {
    guardarLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const { usuario, password } = login;

  const validarUsuario = async (e) => {
    var usuario = document.getElementById('usuario').value;
    var password = document.getElementById('password').value;
    var params = {
      usuario: usuario,
      password: password,
    };
    e.preventDefault();
    const url = process.env.REACT_APP_BACKEND_URL+ '/auth';
    //const url = "/login";
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
      },
    };
    /* const params = new URLSearchParams();
    params.append("username", usuario);
    params.append("password", password);
    params.append("grant_type", "password");
    params.append("client_id", "DC5603C3-D142-43DE-8DA2-7C63802F0E8F");*/

    console.log(params);
    await clienteAxios
      .post(url, params, headers)
      .then((respuesta) => {
        Encrypt('token', respuesta.data.access_token);

        guardarLogueado(true);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };

  return (
    <>
      {!logueado ? (
        <div className="login-contenedor-principal">
          <img id="logo" src="https://www.caffenio.com/img/logos/logo_caffenio-dark.svg"></img>
          <div className="login-contenedor-login">
            <div className="login-contenedor-iniciosesion">
              <h3>Inicio de sesión</h3>

              <img id="separador" src="https://caffenio.com/img/icons/hojas-caffenio.svg"></img>
            </div>

            <form action="" onClick={validarUsuario}>
              <div>
                <label
                  className={css`
                    font-size: 0.9722222222222222vw;
                    font-family: 'Roboto';
                    line-height: 1.429;
                    padding-left: 2.3vw;
                  `}
                >
                  Usuario
                </label>
                <div
                  className={css`
                    padding-bottom: 2.6806640625vh;
                  `}
                >
                  <div className="row">
                    <div className="col-md-1">
                      <span className="material-icons">person</span>
                    </div>
                    <div className="col-md-11">
                      <input
                        type="text"
                        name="usuario"
                        id="usuario"
                        //value={usuario}
                        placeholder="Ingrese su usuario"
                        className="login-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className={css`
                    font-size: 0.9722222222222222vw;
                    font-family: 'Roboto';
                    line-height: 1.429;
                    padding-left: 2.3vw;
                  `}
                >
                  Contraseña
                </label>
                <div
                  className={css`
                    padding-bottom: 4.650390625vh;
                  `}
                >
                  <div className="row">
                    <div className="col-md-1">
                      <span className="material-icons">lock</span>
                    </div>
                    <div className="col-md-11">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        //value={password}
                        placeholder="Ingrese su contraseña"
                        className="login-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={css`
                  padding-bottom: 2.5078125vh;
                  text-align: center;
                `}
              >
                <input type="button" value="Acceder" onClick={validarUsuario} />
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Home />
      )}
    </>
  );
};

export default Login;
