import React from "react";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";
import "./Layout.css";

const Header = (props) => {
  const cerrarSesion = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <header className="header-contenedor">
       {
       // <img src="wp-content/uploads/2021/08/logo.png" alt="" />
       }
        <nav>
          <div className="header-nav">
            <span className="material-icons">account_circle</span>
            
            <a href="#!" onClick={cerrarSesion}>
              Cerrar sesión
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
