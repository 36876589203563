import React from 'react';
import Button from 'react-bootstrap/Button';



const modal = (props) => {
    const onchangeOptions = props.onchangeOptions;
    const idEncuesta = props.idEnc;
    const setModalShow = props.setModalShow;

    const desEnc = () => {
        onchangeOptions(idEncuesta);
        setModalShow(false);
      }

    return (
        <div class="popup" id="fade-flip">
            <div className="modal-wrapper"
                style={{
                    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0',
                    background: 'white',
                    position: 'absolute',
                    top: '10%',
                    right: '14vw',
                    boxShadow:  '0px 0px 0px 100vw rgba(0 0 0 / 36%)',
                    width: '55%'
                    
                }}>
                <div className="modal-header">
                    <h3>¿Quieres desactivar esta encuesta?</h3>
                </div>
                <div className="modal-body">
                  
                </div>
                <div className="modal-footer">
                    <button className="btn-act" onClick={ ()=> desEnc()}>Desactivar</button>
                </div>
            </div>
        </div>
    )
}

export default modal;