import React, { useState, useEffect } from 'react';

import Select from 'react-select';

const options = [
  { value: 'Apertura', label: 'Apertura' },
  { value: 'Nuevo producto', label: 'Nuevo producto' },
  { value: 'Servicio para nuevos clientes', label: 'Servicio para nuevos clientes' },
  { value: 'Nuevo servicio', label: 'Nuevo servicio' },
  { value: 'Proyectos especiales', label: 'Proyectos especiales' }
];

const TipoEncuestas = (props) => {
 

 // const [TipoEncuesta, setTipoEncuesta] = useState();
const detalleTipoEncuesta = props.detalleTipoEncuesta;
const tipoEncuestavalue = props.setTipo_encuesta;
const [valueDetalle , setValueDetalle] = useState('');
const [actualizar, setActualizar] = useState(false);
const [disableTipoEncuesta, setDisableTipoEncuesta] = useState(false);

useEffect(() => {
  if (detalleTipoEncuesta != undefined) {
      setValueDetalle({value:detalleTipoEncuesta,label:detalleTipoEncuesta});
      tipoEncuestavalue(detalleTipoEncuesta);
      if (props.disabled) {
        setDisableTipoEncuesta(true);
      }
  }  
}, [actualizar]);

const onchangeOptions = (e) => {
  tipoEncuestavalue(e.value);
  setValueDetalle(e);
};


  return (
    <>

      <Select options={options} id="tipoEncuesta" name="tipoEncuesta" onChange={onchangeOptions} 
      value={valueDetalle}
      isDisabled={disableTipoEncuesta}
      >        
      </Select>
    </>
  );
};

export default TipoEncuestas;
