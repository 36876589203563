import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import "./css/Formulario.css";
import { Link, useLocation } from 'react-router-dom';
import Encuestas from "../components/tableEncuestas.js";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";

import clienteAxios from "../Utils/axios";

import ReactPaginate from 'react-paginate';

import DetEncuesta from './Det-Formulario'

const Formulario = ({ guardarPagina,setDetalleEncuesta }) => {

    const [encuestas, setEncuesta] = useState([]);
    const [actualizar, setActualizar] = useState(false);

    //pag tabla
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [encuestasDetalle, setEncuestaDetalle] = useState([]);
    useEffect(() => {
        if (actualizar) {
            guardarPagina('formularios');
            Encrypt('pagina', 'formularios');
        }
        setActualizar(false);
        componentDidMount();
    }, [actualizar]);

    
    const [actualizarDetalle, setActualizarDetalle] = useState(false);

    useEffect(() => {
        if (actualizarDetalle) {
            Encrypt("detEnc", encuestasDetalle);
            guardarPagina('detformulario');
            Encrypt('pagina', 'detformulario');
        }
       
        setActualizarDetalle(false);
    }, [actualizarDetalle]);

    const [modificaEncuesta, setModificaEncuesta] = useState(false);
    useEffect(() => {
        if (modificaEncuesta) {
            Encrypt("detEnc", encuestasDetalle);
            guardarPagina('upformulario');
            Encrypt('pagina', 'upformulario');
        }
       
        setModificaEncuesta(false);
    }, [modificaEncuesta]);

    useEffect(() => {
        const endOffset = itemOffset + 10;
        setCurrentItems(encuestas.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(encuestas.length / 10));
      }, [itemOffset, 10]);

    const componentDidMount = async () => {

        const statusEncuesta = document.getElementById("statusEncuesta").value;

        var token = "";
        if (Decrypt("token") != null) {
            token = Decrypt("token");
        }

     

        const url = process.env.REACT_APP_BACKEND_URL+"/encuesta/?fstatus="+statusEncuesta;
        //const url = "/login";
        const headers = {
            headers: {
                //"Content-Type": "application/x-www-form-urlencoded",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                //params:{status:statusEncuesta}
            },
        };
        await clienteAxios
            .get(url, headers)
            .then((respuesta) => {
             
                const enc = respuesta.data.rows;
               // console.log("enc: ",enc);
                setEncuesta(enc);
                const endOffset = itemOffset + 10;
                setCurrentItems(enc.slice(itemOffset, endOffset));
                setPageCount(Math.ceil(enc.length / 10));
               // setActualizar(false);
            })
            .catch((error) => {
                console.log("error:");
                console.log(error);
            });

    }

    const deleteEncuesta = async (id) => {

        var token = "";
        if (Decrypt("token") != null) {
            token = Decrypt("token");
        }

        const url = process.env.REACT_APP_BACKEND_URL+"/encuesta/" + id;
        //const url = "/login";
        const headers = {
            headers: {
                //"Content-Type": "application/x-www-form-urlencoded",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
        };
        await clienteAxios
            .delete(url, headers)
            .then((respuesta) => {
                setActualizar(true);
            })
            .catch((error) => {
                console.log("error:");
                console.log(error);
            });

    }

    const detalleEncuesta = async (e) => {
        var token = "";
        if (Decrypt("token") != null) {
            token = Decrypt("token");
        }

       

        const url =process.env.REACT_APP_BACKEND_URL+"/encuesta/" + e.encuesta_id;
        //const url = "/login";
        const headers = {
            headers: {
                //"Content-Type": "application/x-www-form-urlencoded",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
        };
        await clienteAxios
            .get(url, headers)
            .then((respuesta) => {
                const enc = respuesta.data;
                setDetalleEncuesta(enc);
                setActualizarDetalle(true);               
              
            })
            .catch((error) => {
                console.log("error:");
                console.log(error);
            }); 
    }

    const upEncuesta = async (e) => {
        var token = "";
        if (Decrypt("token") != null) {
            token = Decrypt("token");
        }

       

        const url =process.env.REACT_APP_BACKEND_URL+"/encuesta/" + e.encuesta_id;
        //const url = "/login";
        const headers = {
            headers: {
                //"Content-Type": "application/x-www-form-urlencoded",
                "Content-Type": "application/json",
                //'Authorization': 'Bearer ' + token
            },
        };
        await clienteAxios
            .get(url, headers)
            .then((respuesta) => {
                const enc = respuesta.data;
                setDetalleEncuesta(enc);
                Encrypt("detEnc", enc);                
                setModificaEncuesta(true);   
                
            })
            .catch((error) => {
                console.log("error:");
                console.log(error);
            }); 
    }


    const cambiarPagina = (pagina) => {
        guardarPagina(pagina);
        Encrypt("pagina", pagina);
    };

    const handlePageClick = (event) => {
        
        const newOffset = (event.selected * 10) % encuestas.length;
        setItemOffset(newOffset);
      };

    

    return (
        <>


            <div className="formulario-contenedor">

                <div id="plantilladrive1">
                    <div className="row" style={{ paddingTop: 1 + "vw", paddingBottom: 1 + "vw" }}>
                        <div className="col-md-3">
                            <div className="form-group">
                                <a href="#!" className="btn btn-success" onClick={() => cambiarPagina("addformulario")}>
                                    Agregar Formulario
                                </a>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="form-group">
                                <select className="form-control" id="statusEncuesta" name="statusEncuesta" onChange={componentDidMount} style={{width:30+"%",float:"right"}}>
                                    <option value="true">Activos</option>
                                    <option value="false">Inactivos</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div id="tablaplantilladrive">
                        <fieldset>

                            <table id="tablaForm">

                                <thead>
                                    <tr>
                                        <th scope="col">Encuesta</th>
                                        <th scope="col">Tipo de encuesta</th>
                                        <th scope="col">Nombre de la encuesta</th>
                                        <th scope="col">Fecha Inicio</th>
                                        <th scope="col">Fecha Fin</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        <Encuestas encuestas={currentItems} 
                                        deleteEncuesta={deleteEncuesta} 
                                        detalleEncuesta={detalleEncuesta}
                                        upEncuesta={upEncuesta}
                                         guardarPagina={guardarPagina}
                                         />
                                    }
                                </tbody>
                            </table>
                        </fieldset>
                        {
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="siguiente >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< anterior"
                    renderOnZeroPageCount={null}
                  />
                }
                    </div>



                </div>
            </div>

        </>
    );
};

export default Formulario;
