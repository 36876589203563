import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const TipoLeche = (props) => {
  const options = props.lTipoLeche.map((d) => ({
    'value': d.tipoLeche_id,
    'label': d.descripcion,
    'cf_MilkTypeId': d.cf_MilkTypeId,
  }));

  const changeTipoLeche = props.changeTipoLeche;
  const setSelecteTipoLeche = props.setSelecteTipoLeche;
  const detalleTipoLeche = props.detalleTipoLeche;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleTipoLeche != undefined) {
      const arr = detalleTipoLeche.map((d) => ({
        'value': d.tipoLeche_id,
        'label': d.tipo_leche.descripcion,
        'cf_MilkTypeId': d.tipo_leche.cf_MilkTypeId,
      }));
  
      setValueDetalle(arr);
      setSelecteTipoLeche(arr);
      if (props.disabled) {
        setDisable(true);
      }
    } 
  }, [actualizar]); 

  const onchangeOptions = (e) => {
    changeTipoLeche(e);
    setSelecteTipoLeche(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="tipoEncuesta">Tipo de Leche</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="tipoLeche"
          value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))} 
            isDisabled={disable}
             />
        </div>
      </div>
    </>
  );
};

export default TipoLeche;
