import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const Presentacion = (props) => {
  const options = props.lPresentacion.map((d) => ({
    'value': d.presentacion_id,
    'label': d.nombre,
    'cf_PresentationId': d.cf_PresentationId,
  }));

  const changePresentacion = props.changePresentacion;
  const setSelecteTipoPresentacion = props.setSelecteTipoPresentacion;
  const detallePresentaciones = props.detallePresentaciones;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detallePresentaciones != undefined) {
      const arr = detallePresentaciones.map((d) => ({
        'value': d.presentacion_id,
        'label': d.presentacion.nombre,
        'cf_PresentationId': d.tipo_sabor.cf_PresentationId,
      }));
  
      setValueDetalle(arr);
      setSelecteTipoPresentacion(arr);
      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);
  const onchangeOptions = (e) => {
    changePresentacion(e);
    setSelecteTipoPresentacion(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="tipoEncuesta">Tipo de Presentación</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="presentacion" 
           value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))}
            isDisabled={disable}
            
          />
        </div>
      </div>
    </>
  );
};

export default Presentacion;
