import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";
import clienteAxios from "../Utils/axios";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import Home from "../pages/Home";
import "./css/NewLogin.css";
import addform from "./Add-Formulario";
import { Link } from "react-router-dom";

const EncuestaFolio = (props) => {

  const [_encuesta, setEncuesta] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [getFolio, setGetFolio] = useState(0);

  useEffect(() => {
   
    consultaFolio(props.match.params.folio);
  }, [actualizar]);

  async function consultaFolio(e) {
    setGetFolio(e);
    var params = {
      id: e,
    };

    var token = "";
    if (Decrypt("token") != null) {
      token = Decrypt("token");
    }

    console.log("token");
    console.log(token);


    const url = process.env.REACT_APP_BACKEND_URL + "/cencuesta/" + e;
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token
      },
    };
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        const _encuesta = respuesta.data;
        //validar antes de pasar los datos         
        setEncuesta(_encuesta);
        console.log(_encuesta);
      })
      .catch((error) => {
        console.log("error:");
        console.log(error);
      });



    let pagina = "formularios";
    /*return () => {
      guardarPagina(pagina);
      Encrypt("pagina", pagina);
    };*/
    //return <Modal  />;

    return null;
  }


  return (
    <>
      <div className="login-contenedor-principal-encuesta">
        {/* <img id="logo" src="https://www.caffenio.com/img/logos/logo_caffenio-dark.svg"></img> */}
        <div className="login-contenedor-login">
          <div className="login-contenedor-iniciosesion">
            <h1>ENCUESTA CAFFENIO</h1>
            <p>¡Queremos saber tu opinión!</p>

            <img id="separador" src="https://caffenio.com/img/icons/hojas-caffenio.svg"></img>
          </div>

          <form className="box" action="" method="get">
            <h1>Codigo de encuesta</h1>
            <input type="text" name="encuesta" placeholder="Código" id="folio" value={getFolio}></input>
            {
              // <input type="button" name="" onClick={consultaFolio} value="Enviar"></input>
            }
             <h1>Correo</h1>
            <input type="text" name="correo" placeholder="correo" id="correo" ></input>
            <Link
              to={{
                pathname: "/encuesta",
                params: _encuesta 
              }
              }
            >Enviar </Link>
          </form>

        </div>
      </div>
    </>
  );
};

export default EncuestaFolio;
