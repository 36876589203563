import React from "react";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";
import Sidebar from "./Sidebar";
import Header from "./Header";


const Layout = (props) => {
  const {guardarPagina}=props;
  return (
    <>
      <div className="layout-contenedor-principal">
        <Sidebar guardarPagina = {guardarPagina}/>
        <div className="layout-seccion-principal">
          <Header />
          <main className="layout-main">{props.children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
