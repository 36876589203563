import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const options = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
]

const SelectPrioridad = (props) => {

  const detallePrioridad = props.detallePrioridad;
  const prioridadValue = props.setPrioridadEncuesta;
const [valueDetalle , setValueDetalle] = useState('');
const [actualizar, setActualizar] = useState(false);
const [disable, setDisable] = useState(false);

useEffect(() => {
  if (detallePrioridad != undefined) {
      setValueDetalle({value:detallePrioridad,label:detallePrioridad});
      prioridadValue(detallePrioridad);

      if (props.disabled) {
        setDisable(true);
      }
  }  
}, [actualizar]);

const onchangeOptions = (e) => {
  prioridadValue(e.value);
  setValueDetalle(e);
};
  return (
    <>
      <Select options={options} id="prioridad" name="prioridad" onChange={onchangeOptions} 
      value={valueDetalle}
      isDisabled={disable}>        
      </Select>
    </>
  );
};

export default SelectPrioridad;
