import React, { useState, useEffect } from 'react';
import Select from 'react-select';

let options = [
  { value: 'Todos los Clientes', label: 'Todos los Clientes' },
  { value: 'Clientes Afiliados', label: 'Clientes Afiliados' },
]



const TipoCliente = (props) => {

  const detalleTipoCliente = props.detalleTipoCliente;
  const tipoClienteValue = props.setTipo_cliente;
  const [valueDetalle , setValueDetalle] = useState('');
  const [actualizar, setActualizar] = useState(false);
  const [clienteTipo, setClienteTipo] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleTipoCliente != undefined) {
        setValueDetalle({value:detalleTipoCliente,label:detalleTipoCliente});
        tipoClienteValue({value:detalleTipoCliente,label:detalleTipoCliente});

        if (props.disabled) {
          setDisable(true);
        }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    tipoClienteValue(e.value);
    setValueDetalle(e);
    
  };
 
  return (
    <>
      <Select options={options} id="tipoCliente" name="tipoCliente" onChange={onchangeOptions} 
      value={valueDetalle}
      
      isDisabled={disable}
            
      >
            
      </Select>
    </>
  );
};

export default TipoCliente;
