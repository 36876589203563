import React, { useState, useEffect } from 'react';
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import clienteAxios from "../Utils/axios";
import Modal from '../components/Modal';

const EncuestasTabla = (props) => {
  const deleteEncuesta = props.deleteEncuesta;
  const detalleEncuesta = props.detalleEncuesta;
  const upEncuesta = props.upEncuesta;
  const guardarPagina = props.guardarPagina;
  
  const [encuestasDetalle, setEncuestaDetalle] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [encuesta_id, setEncuesta_id] = useState(0);
  

   const onchangeOptions = (e) => {
    deleteEncuesta(e);
  }

  const dEncuesta = (e) =>{
    detalleEncuesta(e);
  }

const upEnc = (e) => {
  upEncuesta(e);
}

const closeModalHandler = () => {
  setModalShow(false);
}

const openModalHandler = (e) => {
 
  setEncuesta_id(e);
  setModalShow(true);
}

  return (
    <>

      {
       props.encuestas && props.encuestas.map((e) => (
          <tr key={
            e.encuesta_id
          }>
            <td>{e.encuesta_id}</td>
            <td>{e.tipo_encuesta}</td>
            <td>{e.nombre}</td>
            <td>{(new Date(e.vigencia_encuesta_inicio)).toLocaleDateString()}</td>
            <td>{(new Date(e.vigencia_encuesta_fin)).toLocaleDateString()}</td>
            <td id="tdButtons" style={{ width: 18 + "%" }}>
              <button id="btnDet" className="btn btn-lg btn-success glyphicon glyphicon glyphicon-eye-open" onClick={() => dEncuesta(e)}></button>
              <button id="btnEdit" className="btn btn-lg btn-warning glyphicon glyphicon-pencil" onClick={() => upEnc(e)}></button>
              {
                (e.status==true)
                ?<button id="btnExc" className="btn btn-lg btn-danger glyphicon glyphicon-trash" onClick={() => openModalHandler(e.encuesta_id)} ></button>
                :<button id="btnExc" className="btn btn-lg btn-success glyphicon glyphicon-ok" onClick={() => onchangeOptions(e.encuesta_id)} ></button>             
              }
              
            </td>
          </tr>
        ))
        
      }
      <Modal
            className="modal"
            show={modalShow}
            setModalShow={setModalShow}
            close={closeModalHandler}
            onchangeOptions={onchangeOptions}
            idEnc={encuesta_id} 
            >
               
        </Modal>

    </>
  );

}

export default EncuestasTabla;