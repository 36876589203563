import React, { useState, useEffect, Component } from "react";
import clienteAxios from "../Utils/axios";
import "./css/Agenda.css";
import Layout from "../layout/Layout";
import Button from 'react-bootstrap/Button';

import Modal from '../components/Modal';



class Agenda extends Component{
    constructor() {
        super();

        this.state = {
            isShowing: false
        }
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        });
    }

    closeModalHandler = () => {
        this.setState({
            isShowing: false
        });
    }

    render () {
        const bg = {
            overlay: {
              background: "#FFFF00"
            }
        };
       
<div>
        { this.state.isShowing ? <div onClick={this.closeModalHandler} className="back-drop"></div> : null }

        <button className="open-modal-btn" onClick={this.openModalHandler}>Open Modal</button>

        <Modal
            className="modal"
            show={this.state.isShowing}
            close={this.closeModalHandler}>
                
        </Modal>
    </div>


 
    return (

      
    
        <>



            <Layout>

            <a href="#" class="float">
<svg xmlns="https://gira.necodexhost.com/wp-content/uploads/2021/08/plus.svg" viewBox="0 0 24 24"><path d="..."/></svg>
</a>
                <h2>Agenda</h2>
                <div className="Filtros">

                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">Filtros</label>
                        </div>
                    </div>
                    <br></br>
                    <div class="info">
                        <div className="row">
                            <div className="col-md-4">
                                <div>Región</div>
                                <div>
                                    <select class="form-select" aria-label="Default select example">

                                        <option selected>opcion 1</option>
                                        <option value="1">opcion 1</option>
                                        <option value="2">opcion 2</option>
                                        <option value="3">opcion 3</option>
                                        <option value="3">opcion 4</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>Sucursal</div>
                                <div>
                                    <select class="form-select" aria-label="Default select example">

                                        <option selected>opcion 1</option>
                                        <option value="1">opcion 1</option>
                                        <option value="2">opcion 2</option>
                                        <option value="3">opcion 3</option>
                                        <option value="3">opcion 4</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>Ciudad</div>

                                <div>
                                    <select class="form-select" aria-label="Default select example">

                                        <option selected>opcion 1</option>
                                        <option value="1">opcion 1</option>
                                        <option value="2">opcion 2</option>
                                        <option value="3">opcion 3</option>
                                        <option value="3">opcion 4</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div id="buscar-evento" className="col-md-4">
                                <div>Nombre del evento</div>
                                <div>
                                    <input id="buscarevento" type="text" />
                                </div>
                            </div>




                            <div id="agenda-fechas" class="row g-3">
                                <div class="col-sm-4 col-sm-4" id="fechas">
                                    <label htmlFor="FechaInicial">Fecha inicial:</label>
                                    <input type="date" id="inicio" name="inicio" />
                                </div>

                                <div class="col-sm-4 col-sm-4" id="fechas">
                                    <label htmlFor="FechaFinal">Fecha final:</label>
                                    <input type="date" id="final" name="final" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                


                <br></br>

                <div className="eventos">
                    <div className="event" class="col-md-6">
                        <h4 class="sucursal">Sucursal Quiroga</h4>

                    </div>


                    <br></br>
                    <div class="info-evento">
                       
                    <div id="calendario" class="col"><img src="https://gira.necodexhost.com/wp-content/uploads/2021/08/calendario.png"></img></div>
                    <div class="col">
                        <h4 class="AgendaTitulo">Ciudad</h4>
                        <p>Hermosillo, Sonora.</p>
                        <h4 class="AgendaTitulo">Lugar</h4>
                        <p>Holiday Inn - Sala 2</p>
                        <h4 class="AgendaTitulo">Hora</h4>
                        <p>8:20 AM</p>
                    
                        </div>

                        </div>
                        <button id="edita-evento" class="button" onClick={this.openModalHandler}>Editar</button>
                       
                        <button className="open-modal-btn" onClick={this.openModalHandler} >
                            Itinerario
                            
                            </button>
                            
   
                </div>

                <div className="eventos2">
                    <div className="event" class="col-md-6">
                        <h4 class="sucursal">Sucursal Quiroga</h4>

                        <div class="info-evento">
                       
                       <div id="calendario" class="col"><img src="https://gira.necodexhost.com/wp-content/uploads/2021/08/calendario.png"></img></div>
                       <div class="col">
                           <h4 class="AgendaTitulo">Ciudad</h4>
                           <p>Hermosillo, Sonora.</p>
                           <h4 class="AgendaTitulo">Lugar</h4>
                           <p>Holiday Inn - Sala 2</p>
                           <h4 class="AgendaTitulo">Hora</h4>
                           <p>8:20 AM</p>
                       
                           </div>
                           </div>


                           <button id="edita-evento" class="button" onClick={this.openModalHandler}>Editar</button>
                       
                        <button className="open-modal-btn" onClick={this.openModalHandler} >
                            Itinerario
                            
                            </button>
                    </div>


                </div>



                <br></br>
                <hr></hr>
               

            </Layout>
            <Modal
            className="modal"
            show={this.state.isShowing}
            classNames={{ overlay: { background: 'black' } }}
            close={this.closeModalHandler}>
                <div class="info-modal">
                <h3>Contacto</h3>
                <div class="TitFolio">Ponente</div>
              <input type="text" disabled />

              <div class="TitFolio">Telefono</div>
              <input type="text" disabled />
<br></br>
              <div class="TitFolio">Transporte</div>
              <input type="text" disabled />

              <div class="TitFolio">Lugar y dirección de llegada</div>
              <input type="text" disabled />
              
              <br></br>
              <div class="TitFolio">Receptor</div>
              <input type="text" disabled />

              <div class="TitFolio">Hotel</div>
              <input type="text" disabled />

              

              
              
              
              </div>

              
              
              <div class="info-modal">
<h3>Evento</h3>
              <div class="TitFolio">Lugar</div>
              <input type="text" disabled />

              <div class="TitFolio">Fecha</div>
              <input type="date" id="start" name="trip-start" disabled/>

              <div class="TitFolio">No. de Sesiones</div>
              <input type="text" disabled />
<br></br>
              <div class="TitFolio">Horario</div>
              <input id="appt-time" type="time" name="appt-time" value="13:30" disabled/>
              <br></br>
              <div class="TitFolio">Menú</div>
              <input type="text" disabled />

              <div class="TitFolio">Break</div>
              <input id="appt-time" type="time" name="appt-time" value="13:30" disabled/>
              </div>
        </Modal>
        </>
   
   
   );
}
};        

export default Agenda;
