import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import './css/Formulario.css';

import DatePicker from 'react-datepicker';
import { formData } from '../formData';
import TipoEncuestas from '../components/axio_components/select.tipoEncuesta';

import clienteAxios from '../Utils/axios';
import { Encrypt, Decrypt } from '../Utils/EncryptDecrypt';

import SelectDrives from '../components/axio_components/select.drives';
import SelectPlazas from '../components/axio_components/select.plaza';
import SelectPresentacion from '../components/axio_components/select.presentacion';
import SelectTipoRecipe from '../components/axio_components/select.tipoReceta';
import SelectRegiones from '../components/axio_components/select.regiones';
import SelectSucursales from '../components/axio_components/select.sucursal';
import SelectTipoEncuesta from '../components/axio_components/select.tipoEncuesta';
import SelectTipoLeche from '../components/axio_components/select.tipoLeche';
import SelectTipoGrano from '../components/axio_components/select.tipoGrano';
import SelectTipoSabor from '../components/axio_components/select.tipoSabor';
import SelectClasificacion from '../components/axio_components/select.clasificacion';
import NivelCliente from '../components/axio_components/select.clienteNo';
import TablaProductos from '../components/axio_components/table.productos';
import TipoBeneficio from '../components/axio_components/select.tipoBeneficio';
import TipoCliente from '../components/axio_components/select.tipoCliente';
import SelectPrioridad from '../components/axio_components/select.priodidad';

import ReactPaginate from 'react-paginate';
import '../components/css/table.productos.css';

const ModificaFormulario = ({ guardarPagina, detalleEncuesta }) => {


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [dateBeneficio, setDateBeneficio] = useState(new Date());
  const [actualizar, setActualizar] = useState(false);

  const [lPlazas, setlPlazas] = useState([]);
  const [lRegiones, setlRegiones] = useState([]);
  const [lSucursales, setlSucursales] = useState([]);
  const [lDrives, setlDrives] = useState([]);

  //filtros productos
  const [lProductos, setlProductos] = useState([]);
  const [lClasificacion, setlClasificacion] = useState([]);
  const [lTipoReceta, setlTipoReceta] = useState([]);
  const [lTipoSabor, setlTipoSabor] = useState([]);
  const [lTipoLeche, setlTipoLeche] = useState([]);
  const [lTipoGrano, setlTipoGrano] = useState([]);
  const [lPresentacion, setlPrecentacion] = useState([]);

  const [filtroPlazas, setfiltroPlazas] = useState([]);
  const [filtroRegiones, setfiltroRegiones] = useState([]);
  const [filtroSucursales, setfiltroSucursales] = useState([]);
  const [filtroDrives, setfiltroDrives] = useState([]);
  const [filtroProductos, setfiltroProductos] = useState([]);

  const [selectedRegion, setSelecteRegion] = useState([]);
  const [selectedSucursal, setSelecteSucursal] = useState([]);
  const [selectePlaza, setSelectePlaza] = useState([]);
  const [selectePuntoVenta, setSelectePuntoVenta] = useState([]);

  const [selecteClasificacion, setSelecteClasificacion] = useState([]);
  const [selecteTipoReceta, setSelecteTipoReceta] = useState([]);
  const [selecteTipoSabor, setSelecteTipoSabor] = useState([]);
  const [selectTipoGrano, setSelectTipoGrano] = useState([]);
  const [selecteTipoLeche, setSelecteTipoLeche] = useState([]);
  const [selecteTipoPresentacion, setSelecteTipoPresentacion] = useState([]);

  const [prioridadEncuestaValue, setPrioridadEncuesta] = useState('');
  const [tipo_beneficioValue, setTipo_beneficio] = useState('');
  const [tipo_clienteValue, setTipo_cliente] = useState('');
  const [tipo_encuestaValue, setTipo_encuesta] = useState('');
  const [tipo_clienteNivel, setTipo_ClienteNivel] = useState([]);

  const [fechaInicio_encuestaValue, setFechaInicio_encuesta] = useState('');
  const [fechaFin_encuestaValue, setFechaFin_encuesta] = useState('');

  
  const [nombre_encuestaValue, setNombre_encuesta] = useState('');
  const [mensaje_encuestaValue, setMensaje_encuesta] = useState('');
  const [script_encuestaValue, setScript_encuesta] = useState('');
  const [aleatorio_encuestaValue, setAleatorio_encuesta] = useState('');

  const [folios_encuestaValue, setFolios_encuestaValue] = useState('');
  const [caducidadFolios_encuestaValue, setCaducudadFolios_encuestaValue] = useState('');


  const [fClasificacion, setfClasificacion] = useState([]);
  const [fTipoReceta, setfTipoReceta] = useState([]);
  const [fTipoSabor, setfTipoSabor] = useState([]);
  const [fTipoGrano, setfTipoGrano] = useState([]);
  const [fTipoLeche, setfTipoLeche] = useState([]);
  const [fTipoPresentacion, setfTipoPresentacion] = useState([]);

  const [flatClasificacion, setflatClasificacion] = useState(false);
  const [flatReceta, setflatReceta] = useState(false);
  const [flatSabor, setflatSabor] = useState(false);
  const [flatGrano, setflatGrano] = useState(false);
  const [flatLeche, setflatLeche] = useState(false);
  const [flatPresentacion, setflatPresentacion] = useState(false);


  //selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  var support = (function () {
    if (!window.DOMParser) return false;
    var parser = new DOMParser();
    try {
      parser.parseFromString('x', 'text/html');
    } catch (err) {
      return false;
    }
    return true;
  })();

  //pag tabla
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const [status, setStatus] = React.useState(0) // 0: no show, 1: show yes, 2: show no.

  useEffect(() => {
    if (actualizar) {
      guardarPagina('formularios');
      Encrypt('pagina', 'formularios');
    }
    console.log(detalleEncuesta);
    setActualizar(false);
    // setElements(formData[0]);
    getRegiones();
    getSucursales();
    getPlazas();
    getDrives();
    getProductos();
    getClasificacion();
    getTipoReceta();
    getTipoSabor();
    getTipoGrano();
    getTipoLeche();
    getPresentacion();

    if (detalleEncuesta != undefined) {
      radioHandler(detalleEncuesta.beneficios);
      setTipo_cliente(detalleEncuesta.tipo_cliente);
      setNombre_encuesta(detalleEncuesta.nombre);
      setScript_encuesta(detalleEncuesta.script_encuesta);
      setMensaje_encuesta(detalleEncuesta.mensaje);

      setFolios_encuestaValue(detalleEncuesta.folios_disponibles);
      setCaducudadFolios_encuestaValue(detalleEncuesta.vigencia_impresion);

   

      setFechaInicio_encuesta((new Date(detalleEncuesta.vigencia_encuesta_inicio)));
      setFechaFin_encuesta((new Date(detalleEncuesta.vigencia_encuesta_fin)));
      setStartDate((new Date(detalleEncuesta.vigencia_encuesta_inicio)));
      setEndDate((new Date(detalleEncuesta.vigencia_encuesta_fin)));

      setAleatorio_encuesta(detalleEncuesta.aleatorio);

    }
  }, [actualizar]);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(filtroProductos.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filtroProductos.length / 10));
  }, [itemOffset, 10]);



  function seeForm() {
    var terminalOutput = document.getElementById('iframe-add').value;

    terminalOutput = terminalOutput.replace('<script>', '');
    terminalOutput = terminalOutput.replace('</script>', '');

    terminalOutput.substr(1);
    terminalOutput.substr(0, terminalOutput.length - 1);
    const url = terminalOutput;
    let htmlScript = document.getElementById('smcx-sdk');
    const elemento = document.createElement('script');
    const container = document.getElementById('formulario');
    let divClass = document.getElementsByClassName(
      'smcx-widget smcx-embed smcx-show smcx-widget-dark smcx-hide-branding'
    )[0];
    if (!!divClass) {
      // elemento.innerText = url;
      // container.append(elemento);
      divClass = document.getElementsByClassName(
        'smcx-widget smcx-embed smcx-show smcx-widget-dark smcx-hide-branding'
      )[0];
      divClass.remove();
      elemento.innerText = url;
      container.append(elemento);
      setTimeout(() => {
        divClass = document.getElementsByClassName(
          'smcx-widget smcx-embed smcx-show smcx-widget-dark smcx-hide-branding'
        )[0];
        htmlScript = document.getElementById('smcx-sdk');
        let root = htmlScript.parentNode;
        root.removeChild(document.getElementById('smcx-sdk'));
        root.removeChild(document.getElementById('__smcx__'));
        container.append(divClass);
        document.getElementsByClassName('smcx-iframe-container')[0].style.height = '800px';
      }, 2000);
    } else {
      elemento.innerText = url;
      container.append(elemento);
      setTimeout(() => {
        divClass = document.getElementsByClassName(
          'smcx-widget smcx-embed smcx-show smcx-widget-dark smcx-hide-branding'
        )[0];
        htmlScript = document.getElementById('smcx-sdk');
        let root = htmlScript.parentNode;
        root.removeChild(document.getElementById('smcx-sdk'));
        root.removeChild(document.getElementById('__smcx__'));
        container.append(divClass);
        document.getElementsByClassName('smcx-iframe-container')[0].style.height = '800px';
      }, 2000);
    }
  }

  const updateForm = async (id) => {
    let titulo = document.getElementById('titulo').value;
    let tipoEncuesta = document.getElementById('tipoEncuesta').value;
    let prioridad = document.getElementById('prioridad').value;

    let tipoCliente = document.getElementById('tipoCliente').value;
    let vigenciaInicio = document.getElementById('vigenciaEncuestaInicio').value;
    let vigenciaFin = document.getElementById('vigenciaEncuestaFin').value;
    let vigenciaDias = document.getElementById('vigenciaDias').value;
    let aleatorio = document.getElementById('aleatorio').value;
    let decision = document.getElementById('decision').value;
    let folios = document.getElementById('folios').value;
    let mensaje = document.getElementById('mensaje').value;
    let script = document.getElementById('iframe-add').value;

    script = script.replace('<script>', '');
    script = script.replace('</script>', '');


    //let tipo_beneficio = document.getElementById('tipoBeneficio').value;
    let cantidad = 0;
    let descuento = 0;
    let vigencia_beneficio = "";

    if (status == 1) {

      const cantidadValue =document.getElementById('cantBeneficio').value;
      const descuentoValue =document.getElementById('descBeneficio').value;

      if (cantidadValue!="") {
        cantidad = document.getElementById('cantBeneficio').value;
      }
      if (descuentoValue!="") {
        descuento = document.getElementById('descBeneficio').value;
      }  
     
      //vigencia_beneficio = document.getElementById('vigenciaBeneficio').value;
  
    }
    else {
      decision = 'false';
    }

    var params = {
      select_region: selectedRegion,
      select_sucursal: selectedSucursal,
      selec_plaza: selectePlaza,
      drive: selectePuntoVenta,
      select_clasificacion: selecteClasificacion,
      select_tipoReceta: selecteTipoReceta,
      select_tipoSabor: selecteTipoSabor,
      select_tipoGrano: selectTipoGrano,
      select_tipoLeche: selecteTipoLeche,
      select_tipoPresentacion: selecteTipoPresentacion,
      nombre: titulo,
      script_encuesta: script,
      tipo_encuesta: tipo_encuestaValue,
      tipo_cliente: tipo_clienteValue,
      vigencia_impresion: vigenciaDias,
      vigencia_encuesta_inicio: new Date(vigenciaInicio),
      vigencia_encuesta_fin: new Date(vigenciaFin),
      folios_disponibles: folios,
      aleatorio: aleatorio,
      prioridad: prioridadEncuestaValue,
      beneficios: decision,
      mensaje: mensaje,
      producto: filtroProductos,
      tipo_beneficio: tipo_beneficioValue,
      cantidad_beneficio: cantidad,
      descuento_beneficio: descuento,
     // vigencia_beneficio: vigencia_beneficio,
      cliente_nivel:tipo_clienteNivel
    };
    console.log("params ", params);
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + "/encuesta/" + id;
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };

    await clienteAxios
      .put(url, params, headers)
      .then((respuesta) => {
        console.log('resp;');
        console.log(respuesta);
        // const _encuesta = respuesta.data.rows;

        setActualizar(true);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });

    return null;
  }



  const [elements, setElements] = useState(null);

  const { fields, page_label } = elements ?? {};
  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(elements);
  };


  const getRegiones = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/Region';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlRegiones(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  //changeRegion
  const changeRegion = (regiones) => {
    if (regiones.length > 0) {
      let filterSucursales = [];
      let regionesSelected = [];
      regiones.forEach((r) => {
        let resultSucursales = lSucursales.filter((s) => s.region_id == r.value);
        filterSucursales.push(resultSucursales);
        regionesSelected.push(r.value);
      });
      setfiltroSucursales(filterSucursales.flat());
      setSelecteRegion(regionesSelected.flat());
    } else {
      setfiltroSucursales(lSucursales);
    }
  };

  // const changeSucursal
  const changeSucursal = (sucursales) => {
    if (sucursales.length > 0) {
      let filterPlazas = [];
      let sucursalSelected = [];
      sucursales.forEach((r) => {
        let resultPlazas = lPlazas.filter((s) => s.sucursal_id == r.value);
        filterPlazas.push(resultPlazas);
      });
      setfiltroPlazas(filterPlazas.flat());
      setSelecteSucursal(sucursalSelected.flat());
    } else {
      setfiltroPlazas(lPlazas);
    }
  };

  // const changePlaza
  const changePlaza = (plazas) => {
    if (plazas.length > 0) {
      let filterDrives = [];
      let plazasSelected = [];
      plazas.forEach((r) => {
        let resultDrives = lDrives.filter((s) => s.idPlazaAdministrativa == r.value);
        //debugger;
        filterDrives.push(resultDrives);
      });
      setfiltroDrives(filterDrives.flat());
      setSelecteRegion(plazasSelected.flat());
    } else {
      setfiltroDrives(lDrives);
    }
  };

  // const changeClasificacion
  const changeClasificacion = (clasificacion) => {

    if (filtroProductos.length < lProductos.length) {

    }

    if (clasificacion.length > 0) {
      let filterProductos = [];
      let clasificacionSelected = [];
      clasificacion.forEach((r) => {
        let resultDrives = lProductos.filter((s) => s.ClassificationId == r.cf_clasificacionId);
        filterProductos.push(resultDrives);
        clasificacionSelected.push(r);
      });

      const endOffset = itemOffset + 10;
      setCurrentItems(filterProductos.flat().slice(itemOffset, endOffset));

      setPageCount(Math.ceil(filterProductos.flat().length / 10));

      setfiltroProductos(filterProductos.flat());
      setSelecteClasificacion(clasificacionSelected.flat());

      //filtrar clasificacion
      /* const uniqueClasificacion = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
       let filterClasificacion = [];
       uniqueClasificacion.forEach((r) => {
         let resFClasificacion = lProductos.filter((s) => s.ClassificationId == r);
         filterClasificacion.push(resFClasificacion);
       });
       setfTipoReceta(filterClasificacion.flat());*/

      //filtrar tipo receta
      const uniqueTipoReceta = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterReceta = [];
      uniqueTipoReceta.forEach((r) => {
        let resFTipoReceta = lTipoReceta.filter((s) => s.cf_RecipeTypeId == r);
        filterReceta.push(resFTipoReceta);
      });
      setfTipoReceta(filterReceta.flat());

      //filtrar tipo sabor
      const uniqueTipoSabor = [...new Set(filterProductos.flat().map((item) => item.FlavorTypeId))];
      let filterSabor = [];
      uniqueTipoSabor.forEach((r) => {
        let resFTipoSabor = lTipoSabor.filter((s) => s.cf_FlavorTypeId == r);
        filterSabor.push(resFTipoSabor);
      });
      setfTipoSabor(filterSabor.flat());

      //filtrar tipo grano
      const uniqueTipoGrano = [...new Set(filterProductos.flat().map((item) => item.GrainTypeId))];
      let filterGrano = [];
      uniqueTipoGrano.forEach((r) => {
        let resFTipoGrano = lTipoGrano.filter((s) => s.cf_GrainTypeId == r);
        filterGrano.push(resFTipoGrano);
      });
      setfTipoGrano(filterGrano.flat());

      //filtrar tipo leche
      const uniqueTipoLeche = [...new Set(filterProductos.flat().map((item) => item.MilkTypeId))];
      let filterLeche = [];
      uniqueTipoLeche.forEach((r) => {
        let resFTipoLeche = lTipoLeche.filter((s) => s.cf_MilkTypeId == r);
        filterLeche.push(resFTipoLeche);
      });
      setfTipoLeche(filterLeche.flat());

      //filtrar presentacion
      const uniquePresentacion = [...new Set(filterProductos.flat().map((item) => item.PresentationId))];
      let filterPresentacion = [];
      uniquePresentacion.forEach((r) => {
        let resPresentacion = lPresentacion.filter((s) => s.cf_PresentationId == r);
        filterPresentacion.push(resPresentacion);
      });
      setfTipoPresentacion(filterPresentacion.flat());
      setflatClasificacion(true);
    } else {

      setflatClasificacion(false);

      if (flatReceta != false) {
        changeTipoReceta(selecteTipoReceta);
      }
      else if (flatSabor != false) {
        changeTipoSabor(selecteTipoSabor);
      }
      else if (flatGrano != false) {
        changeTipoGrano(selectTipoGrano);
      }
      else if (flatLeche != false) {
        changeTipoLeche(selecteTipoLeche);
      }
      else if (flatPresentacion != false) {
        changePresentacion(selecteTipoPresentacion);
      }

      else {
        const endOffset = itemOffset + 10;
        setCurrentItems(lProductos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lProductos.length / 10));
        setfiltroProductos(lProductos);
        setfTipoReceta(lTipoReceta);
        setfTipoSabor(lTipoSabor);
        setfTipoGrano(lTipoGrano);
        setfTipoLeche(lTipoLeche);
        setfTipoPresentacion(lPresentacion);
      }


    }
  };

  // const changeTipoReceta
  const changeTipoReceta = (tr) => {
    if (tr.length > 0) {

      let filterProductos = [];
      let tipoRecetaSelected = [];
      tr.forEach((r) => {
        let resultProducto = lProductos.filter((s) => s.RecipeTypeId == r.cf_RecipeTypeId);
        //debugger;
        filterProductos.push(resultProducto);
        tipoRecetaSelected.push(r);
      });

      const endOffset = itemOffset + 10;
      setCurrentItems(filterProductos.flat().slice(itemOffset, endOffset));

      setPageCount(Math.ceil(filterProductos.flat().length / 10));

      setfiltroProductos(filterProductos.flat());
      setSelecteTipoReceta(tipoRecetaSelected.flat());

      //filtrar clasificacion
      const uniqueClasificacion = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterClasificacion = [];
      uniqueClasificacion.forEach((r) => {
        let resFClasificacion = lProductos.filter((s) => s.ClassificationId == r);
        filterClasificacion.push(resFClasificacion);
      });
      setfTipoReceta(filterClasificacion.flat());

      //filtrar tipo receta
      // const uniqueTipoReceta = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      // let filterReceta = [];
      // uniqueTipoReceta.forEach((r) => {
      //   let resFTipoReceta = lTipoReceta.filter((s) => s.cf_RecipeTypeId == r);
      //   filterReceta.push(resFTipoReceta);
      // });
      // setfTipoReceta(filterReceta.flat());

      //filtrar tipo sabor
      const uniqueTipoSabor = [...new Set(filterProductos.flat().map((item) => item.FlavorTypeId))];
      let filterSabor = [];
      uniqueTipoSabor.forEach((r) => {
        let resFTipoSabor = lTipoSabor.filter((s) => s.cf_FlavorTypeId == r);
        filterSabor.push(resFTipoSabor);
      });
      setfTipoSabor(filterSabor.flat());

      //filtrar tipo grano
      const uniqueTipoGrano = [...new Set(filterProductos.flat().map((item) => item.GrainTypeId))];
      let filterGrano = [];
      uniqueTipoGrano.forEach((r) => {
        let resFTipoGrano = lTipoGrano.filter((s) => s.cf_GrainTypeId == r);
        filterGrano.push(resFTipoGrano);
      });
      setfTipoGrano(filterGrano.flat());

      //filtrar tipo leche
      const uniqueTipoLeche = [...new Set(filterProductos.flat().map((item) => item.MilkTypeId))];
      let filterLeche = [];
      uniqueTipoLeche.forEach((r) => {
        let resFTipoLeche = lTipoLeche.filter((s) => s.cf_MilkTypeId == r);
        filterLeche.push(resFTipoLeche);
      });
      setfTipoLeche(filterLeche.flat());

      //filtrar presentacion
      const uniquePresentacion = [...new Set(filterProductos.flat().map((item) => item.PresentationId))];
      let filterPresentacion = [];
      uniquePresentacion.forEach((r) => {
        let resPresentacion = lPresentacion.filter((s) => s.cf_PresentationId == r);
        filterPresentacion.push(resPresentacion);
      });
      setfTipoPresentacion(filterPresentacion.flat());
      setflatReceta(true);
    } else {
      setflatReceta(false);
      if (flatClasificacion != false) {
        changeClasificacion(selecteClasificacion);
      }
      else if (flatSabor != false) {
        changeTipoSabor(selecteTipoSabor);
      }
      else if (flatGrano != false) {
        changeTipoGrano(selectTipoGrano);
      }
      else if (flatLeche != false) {
        changeTipoLeche(selecteTipoLeche);
      }
      else if (flatPresentacion != false) {
        changePresentacion(selecteTipoPresentacion);
      }

      else {
        const endOffset = itemOffset + 10;
        setCurrentItems(lProductos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lProductos.length / 10));
        setfiltroProductos(lProductos);
        setfTipoReceta(lTipoReceta);
        setfTipoSabor(lTipoSabor);
        setfTipoGrano(lTipoGrano);
        setfTipoLeche(lTipoLeche);
        setfTipoPresentacion(lPresentacion);
      }
    }
  };

  // const changeTipoSabor
  const changeTipoSabor = (tr) => {
    if (tr.length > 0) {
      let filterProductos = [];
      let tipoSaborSelected = [];
      tr.forEach((r) => {
        let resultProducto = filtroProductos.filter((s) => s.FlavorTypeId == r.cf_FlavorTypeId);
        //debugger;
        filterProductos.push(resultProducto);
        tipoSaborSelected.push(r);
      });

      const endOffset = itemOffset + 10;
      setCurrentItems(filterProductos.flat().slice(itemOffset, endOffset));

      setPageCount(Math.ceil(filterProductos.flat().length / 10));

      setfiltroProductos(filterProductos.flat());
      setSelecteTipoSabor(tipoSaborSelected.flat());

      //filtrar clasificacion
      const uniqueClasificacion = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterClasificacion = [];
      uniqueClasificacion.forEach((r) => {
        let resFClasificacion = lProductos.filter((s) => s.ClassificationId == r);
        filterClasificacion.push(resFClasificacion);
      });
      setfTipoReceta(filterClasificacion.flat());

      //filtrar tipo receta
      const uniqueTipoReceta = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterReceta = [];
      uniqueTipoReceta.forEach((r) => {
        let resFTipoReceta = lTipoReceta.filter((s) => s.cf_RecipeTypeId == r);
        filterReceta.push(resFTipoReceta);
      });
      setfTipoReceta(filterReceta.flat());

      //filtrar tipo sabor
      // const uniqueTipoSabor = [...new Set(filterProductos.flat().map((item) => item.FlavorTypeId))];
      // let filterSabor = [];
      // uniqueTipoSabor.forEach((r) => {
      //   let resFTipoSabor = lTipoSabor.filter((s) => s.cf_FlavorTypeId == r);
      //   filterSabor.push(resFTipoSabor);
      // });
      // setfTipoSabor(filterSabor.flat());

      //filtrar tipo grano
      const uniqueTipoGrano = [...new Set(filterProductos.flat().map((item) => item.GrainTypeId))];
      let filterGrano = [];
      uniqueTipoGrano.forEach((r) => {
        let resFTipoGrano = lTipoGrano.filter((s) => s.cf_GrainTypeId == r);
        filterGrano.push(resFTipoGrano);
      });
      setfTipoGrano(filterGrano.flat());

      //filtrar tipo leche
      const uniqueTipoLeche = [...new Set(filterProductos.flat().map((item) => item.MilkTypeId))];
      let filterLeche = [];
      uniqueTipoLeche.forEach((r) => {
        let resFTipoLeche = lTipoLeche.filter((s) => s.cf_MilkTypeId == r);
        filterLeche.push(resFTipoLeche);
      });
      setfTipoLeche(filterLeche.flat());

      //filtrar presentacion
      const uniquePresentacion = [...new Set(filterProductos.flat().map((item) => item.PresentationId))];
      let filterPresentacion = [];
      uniquePresentacion.forEach((r) => {
        let resPresentacion = lPresentacion.filter((s) => s.cf_PresentationId == r);
        filterPresentacion.push(resPresentacion);
      });
      setfTipoPresentacion(filterPresentacion.flat());
      setflatSabor(true);
    } else {
      setflatSabor(false);

      if (flatClasificacion != false) {
        changeClasificacion(selecteClasificacion);
      }
      else if (flatReceta != false) {
        changeTipoReceta(selecteTipoReceta);
      }
      else if (flatGrano != false) {
        changeTipoGrano(selectTipoGrano);
      }
      else if (flatLeche != false) {
        changeTipoLeche(selecteTipoLeche);
      }
      else if (flatPresentacion != false) {
        changePresentacion(selecteTipoPresentacion);
      }

      else {
        const endOffset = itemOffset + 10;
        setCurrentItems(lProductos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lProductos.length / 10));
        setfiltroProductos(lProductos);
        setfTipoReceta(lTipoReceta);
        setfTipoSabor(lTipoSabor);
        setfTipoGrano(lTipoGrano);
        setfTipoLeche(lTipoLeche);
        setfTipoPresentacion(lPresentacion);
      }
    }
  };

  // const changeTipoGrano
  const changeTipoGrano = (tr) => {
    if (tr.length > 0) {
      let filterProductos = [];
      let tipoGranoSelected = [];
      tr.forEach((r) => {
        let resultProducto = filtroProductos.filter((s) => s.GrainTypeId == r.cf_GrainTypeId);
        //debugger;
        filterProductos.push(resultProducto);
        tipoGranoSelected.push(r);
      });

      const endOffset = itemOffset + 10;
      setCurrentItems(filterProductos.flat().slice(itemOffset, endOffset));

      setPageCount(Math.ceil(filterProductos.flat().length / 10));

      setfiltroProductos(filterProductos.flat());
      setSelectTipoGrano(tipoGranoSelected.flat());

      //filtrar clasificacion
      const uniqueClasificacion = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterClasificacion = [];
      uniqueClasificacion.forEach((r) => {
        let resFClasificacion = lProductos.filter((s) => s.ClassificationId == r);
        filterClasificacion.push(resFClasificacion);
      });
      setfTipoReceta(filterClasificacion.flat());

      //filtrar tipo receta
      const uniqueTipoReceta = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterReceta = [];
      uniqueTipoReceta.forEach((r) => {
        let resFTipoReceta = lTipoReceta.filter((s) => s.cf_RecipeTypeId == r);
        filterReceta.push(resFTipoReceta);
      });
      setfTipoReceta(filterReceta.flat());

      //filtrar tipo sabor
      const uniqueTipoSabor = [...new Set(filterProductos.flat().map((item) => item.FlavorTypeId))];
      let filterSabor = [];
      uniqueTipoSabor.forEach((r) => {
        let resFTipoSabor = lTipoSabor.filter((s) => s.cf_FlavorTypeId == r);
        filterSabor.push(resFTipoSabor);
      });
      setfTipoSabor(filterSabor.flat());

      //filtrar tipo grano
      // const uniqueTipoGrano = [...new Set(filterProductos.flat().map((item) => item.GrainTypeId))];
      // let filterGrano = [];
      // uniqueTipoGrano.forEach((r) => {
      //   let resFTipoGrano = lTipoGrano.filter((s) => s.cf_GrainTypeId == r);
      //   filterGrano.push(resFTipoGrano);
      // });
      // setfTipoGrano(filterGrano.flat());

      //filtrar tipo leche
      const uniqueTipoLeche = [...new Set(filterProductos.flat().map((item) => item.MilkTypeId))];
      let filterLeche = [];
      uniqueTipoLeche.forEach((r) => {
        let resFTipoLeche = lTipoLeche.filter((s) => s.cf_MilkTypeId == r);
        filterLeche.push(resFTipoLeche);
      });
      setfTipoLeche(filterLeche.flat());

      //filtrar presentacion
      const uniquePresentacion = [...new Set(filterProductos.flat().map((item) => item.PresentationId))];
      let filterPresentacion = [];
      uniquePresentacion.forEach((r) => {
        let resPresentacion = lPresentacion.filter((s) => s.cf_PresentationId == r);
        filterPresentacion.push(resPresentacion);
      });
      setfTipoPresentacion(filterPresentacion.flat());
      setflatGrano(true);
    } else {
      setflatGrano(false);
      if (flatClasificacion != false) {
        changeClasificacion(selecteClasificacion);
      }
      else if (flatReceta != false) {
        changeTipoReceta(selecteTipoReceta);
      }
      else if (flatSabor != false) {
        changeTipoSabor(selecteTipoSabor);
      }
      else if (flatLeche != false) {
        changeTipoLeche(selecteTipoLeche);
      }
      else if (flatPresentacion != false) {
        changePresentacion(selecteTipoPresentacion);
      }

      else {
        const endOffset = itemOffset + 10;
        setCurrentItems(lProductos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lProductos.length / 10));
        setfiltroProductos(lProductos);
        setfTipoReceta(lTipoReceta);
        setfTipoSabor(lTipoSabor);
        setfTipoGrano(lTipoGrano);
        setfTipoLeche(lTipoLeche);
        setfTipoPresentacion(lPresentacion);
      }
    }
  };

  // const changeTipoLeche
  const changeTipoLeche = (tr) => {
    if (tr.length > 0) {
      let filterProductos = [];
      let tipoLecheSelected = [];
      tr.forEach((r) => {
        let resultProducto = filtroProductos.filter((s) => s.MilkTypeId == r.cf_MilkTypeId);
        //debugger;
        filterProductos.push(resultProducto);
        tipoLecheSelected.push(r);
      });

      const endOffset = itemOffset + 10;
      setCurrentItems(filterProductos.flat().slice(itemOffset, endOffset));

      setPageCount(Math.ceil(filterProductos.flat().length / 10));

      setfiltroProductos(filterProductos.flat());
      setSelecteTipoLeche(tipoLecheSelected.flat());

      //filtrar clasificacion
      const uniqueClasificacion = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterClasificacion = [];
      uniqueClasificacion.forEach((r) => {
        let resFClasificacion = lProductos.filter((s) => s.ClassificationId == r);
        filterClasificacion.push(resFClasificacion);
      });
      setfTipoReceta(filterClasificacion.flat());

      //filtrar tipo receta
      const uniqueTipoReceta = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterReceta = [];
      uniqueTipoReceta.forEach((r) => {
        let resFTipoReceta = lTipoReceta.filter((s) => s.cf_RecipeTypeId == r);
        filterReceta.push(resFTipoReceta);
      });
      setfTipoReceta(filterReceta.flat());

      //filtrar tipo sabor
      const uniqueTipoSabor = [...new Set(filterProductos.flat().map((item) => item.FlavorTypeId))];
      let filterSabor = [];
      uniqueTipoSabor.forEach((r) => {
        let resFTipoSabor = lTipoSabor.filter((s) => s.cf_FlavorTypeId == r);
        filterSabor.push(resFTipoSabor);
      });
      setfTipoSabor(filterSabor.flat());

      //filtrar tipo grano
      const uniqueTipoGrano = [...new Set(filterProductos.flat().map((item) => item.GrainTypeId))];
      let filterGrano = [];
      uniqueTipoGrano.forEach((r) => {
        let resFTipoGrano = lTipoGrano.filter((s) => s.cf_GrainTypeId == r);
        filterGrano.push(resFTipoGrano);
      });
      setfTipoGrano(filterGrano.flat());

      //filtrar tipo leche
      // const uniqueTipoLeche = [...new Set(filterProductos.flat().map((item) => item.MilkTypeId))];
      // let filterLeche = [];
      // uniqueTipoLeche.forEach((r) => {
      //   let resFTipoLeche = lTipoLeche.filter((s) => s.cf_MilkTypeId == r);
      //   filterLeche.push(resFTipoLeche);
      // });
      // setfTipoLeche(filterLeche.flat());

      //filtrar presentacion
      const uniquePresentacion = [...new Set(filterProductos.flat().map((item) => item.PresentationId))];
      let filterPresentacion = [];
      uniquePresentacion.forEach((r) => {
        let resPresentacion = lPresentacion.filter((s) => s.cf_PresentationId == r);
        filterPresentacion.push(resPresentacion);
      });
      setfTipoPresentacion(filterPresentacion.flat());
      setflatLeche(true);
    } else {
      setflatLeche(false);
      if (flatClasificacion != false) {
        changeClasificacion(selecteClasificacion);
      }
      else if (flatReceta != false) {
        changeTipoReceta(selecteTipoReceta);
      }
      else if (flatSabor != false) {
        changeTipoSabor(selecteTipoSabor);
      }
      else if (flatGrano != false) {
        changeTipoGrano(selectTipoGrano);
      }
      else if (flatPresentacion != false) {
        changePresentacion(selecteTipoPresentacion);
      }

      else {
        const endOffset = itemOffset + 10;
        setCurrentItems(lProductos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lProductos.length / 10));
        setfiltroProductos(lProductos);
        setfTipoReceta(lTipoReceta);
        setfTipoSabor(lTipoSabor);
        setfTipoGrano(lTipoGrano);
        setfTipoLeche(lTipoLeche);
        setfTipoPresentacion(lPresentacion);
      }
    }
  };

  // const changePresentacion
  const changePresentacion = (tr) => {
    if (tr.length > 0) {
      let filterProductos = [];
      let TipoPresentacionSelected = [];
      tr.forEach((r) => {
        let resultProducto = filtroProductos.filter((s) => s.PresentationId == r.cf_PresentationId);
        //debugger;
        filterProductos.push(resultProducto);
        TipoPresentacionSelected.push(r);
      });

      const endOffset = itemOffset + 10;
      setCurrentItems(filterProductos.flat().slice(itemOffset, endOffset));

      setPageCount(Math.ceil(filterProductos.flat().length / 10));

      setfiltroProductos(filterProductos.flat());
      setSelecteTipoPresentacion(TipoPresentacionSelected.flat());

      //filtrar clasificacion
      const uniqueClasificacion = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterClasificacion = [];
      uniqueClasificacion.forEach((r) => {
        let resFClasificacion = lProductos.filter((s) => s.ClassificationId == r);
        filterClasificacion.push(resFClasificacion);
      });
      setfTipoReceta(filterClasificacion.flat());

      //filtrar tipo receta
      const uniqueTipoReceta = [...new Set(filterProductos.flat().map((item) => item.RecipeTypeId))];
      let filterReceta = [];
      uniqueTipoReceta.forEach((r) => {
        let resFTipoReceta = lTipoReceta.filter((s) => s.cf_RecipeTypeId == r);
        filterReceta.push(resFTipoReceta);
      });
      setfTipoReceta(filterReceta.flat());

      //filtrar tipo sabor
      const uniqueTipoSabor = [...new Set(filterProductos.flat().map((item) => item.FlavorTypeId))];
      let filterSabor = [];
      uniqueTipoSabor.forEach((r) => {
        let resFTipoSabor = lTipoSabor.filter((s) => s.cf_FlavorTypeId == r);
        filterSabor.push(resFTipoSabor);
      });
      setfTipoSabor(filterSabor.flat());

      //filtrar tipo grano
      const uniqueTipoGrano = [...new Set(filterProductos.flat().map((item) => item.GrainTypeId))];
      let filterGrano = [];
      uniqueTipoGrano.forEach((r) => {
        let resFTipoGrano = lTipoGrano.filter((s) => s.cf_GrainTypeId == r);
        filterGrano.push(resFTipoGrano);
      });
      setfTipoGrano(filterGrano.flat());

      //filtrar tipo leche
      const uniqueTipoLeche = [...new Set(filterProductos.flat().map((item) => item.MilkTypeId))];
      let filterLeche = [];
      uniqueTipoLeche.forEach((r) => {
        let resFTipoLeche = lTipoLeche.filter((s) => s.cf_MilkTypeId == r);
        filterLeche.push(resFTipoLeche);
      });
      setfTipoLeche(filterLeche.flat());

      setflatPresentacion(true);
      //filtrar presentacion
      // const uniquePresentacion = [...new Set(filterProductos.flat().map((item) => item.PresentationId))];
      // let filterPresentacion = [];
      // uniquePresentacion.forEach((r) => {
      //   let resPresentacion = lPresentacion.filter((s) => s.cf_PresentationId == r);
      //   filterPresentacion.push(resPresentacion);
      // });
      // setfTipoPresentacion(filterPresentacion.flat());
    } else {
      setflatPresentacion(false);
      if (flatClasificacion != false) {
        changeClasificacion(selecteClasificacion);
      }
      else if (flatReceta != false) {
        changeTipoReceta(selecteTipoReceta);
      }
      else if (flatSabor != false) {
        changeTipoSabor(selecteTipoSabor);
      }
      else if (flatGrano != false) {
        changeTipoGrano(selectTipoGrano);
      }
      else if (flatLeche != false) {
        changeTipoLeche(selecteTipoLeche);
      }

      else {
        const endOffset = itemOffset + 10;
        setCurrentItems(lProductos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lProductos.length / 10));
        setfiltroProductos(lProductos);
        setfTipoReceta(lTipoReceta);
        setfTipoSabor(lTipoSabor);
        setfTipoGrano(lTipoGrano);
        setfTipoLeche(lTipoLeche);
        setfTipoPresentacion(lPresentacion);
      }
    }
  };

  const getSucursales = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/Sucursal';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlSucursales(respuesta.data.rows);
        setfiltroSucursales(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getPlazas = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/Plaza';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlPlazas(respuesta.data.rows);
        setfiltroPlazas(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getDrives = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/drive';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlDrives(respuesta.data.rows);
        setfiltroDrives(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  //productos y filtros
  const getProductos = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    //const url = process.env.REACT_APP_BACKEND_URL + 'http://10.1.1.201:8484/api/v1/Recipe';
    const url = 'http://10.1.1.201:8484/api/v1/Recipe';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlProductos(respuesta.data);
        setfiltroProductos(respuesta.data);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getClasificacion = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/clasificacion';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlClasificacion(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getTipoReceta = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/tiporeceta';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlTipoReceta(respuesta.data.rows);
        setfTipoReceta(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getTipoSabor = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/tiposabor';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlTipoSabor(respuesta.data.rows);
        setfTipoSabor(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getTipoGrano = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/tipograno';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlTipoGrano(respuesta.data.rows);
        setfTipoGrano(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getTipoLeche = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/tipoleche';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlTipoLeche(respuesta.data.rows);
        setfTipoLeche(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const getPresentacion = async () => {
    var token = '';
    if (Decrypt('token') != null) {
      token = Decrypt('token');
    }

    const url = process.env.REACT_APP_BACKEND_URL + '/presentacion';
    const headers = {
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    let params = {};
    await clienteAxios
      .get(url, headers)
      .then((respuesta) => {
        setlPrecentacion(respuesta.data.rows);
        setfTipoPresentacion(respuesta.data.rows);
      })
      .catch((error) => {
        console.log('error:');
        console.log(error);
      });
  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % filtroProductos.length;

    setItemOffset(newOffset);
  };
  const handleChange = (options) => {
    console.log(options);
    //setSelectedOptions(options);
  };
  /*radios*/



  const radioHandler = (status) => {
    if (status==true) {
      status=1;
    }
    else{
      status=2;
    }

    setStatus(status);
    console.log(status);
    /* if (status == 1) {
       document.getElementById('tipoCliente').style.display = 'none';
       document.getElementById('tipoCliente2').style.display = 'block';
     } else {
       document.getElementById('tipoCliente').style.display = 'block';
       document.getElementById('tipoCliente2').style.display = 'none';
     }*/
  };

  const handleChangeNombre = (e) => {
      setNombre_encuesta(e.target.value);
   };
   const handleChangeMensaje= (e) => {
    setMensaje_encuesta(e.target.value);
 };
 const handleChangeScript= (e) => {
  setScript_encuesta(e.target.value);
};
const handleChangeFolios= (e) => {
  setFolios_encuestaValue(e.target.value);
};
const handleChangeCaducidadFolios= (e) => {
  setCaducudadFolios_encuestaValue(e.target.value);
};

const handleChangeFechainicio= (e) => {
  setFechaInicio_encuesta(new Date(e));
  setStartDate(new Date(e));
};

const handleChangeFechaFin= (e) => {

  setFechaFin_encuesta(new Date(e));
  setEndDate(new Date(e));

};

const handleChangeAleatorio= (e) => {
  setAleatorio_encuesta(e.target.value);
};

  return (
    <>
      <div
        className="formulario-contenedor-botones"
        id="uno-uno"
        style={{ marginTop: 0 + 'px', padding: '50px !important' }}
      >
        <form id="newForm">
          <div className="row" style={{ marginBottom: 0 + 'px' }}>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="titulo">Titulo de Encuesta</label>
                <input type="text" name="titulo" id="titulo" className="form-control" 
                value={nombre_encuestaValue} 
                onChange={handleChangeNombre} />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="tipoEncuesta">Tipo de Encuesta</label>
                <TipoEncuestas detalleTipoEncuesta={detalleEncuesta.tipo_encuesta}
                  setTipo_encuesta={setTipo_encuesta}
                />

              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="prioridad">Prioridad de la Encuesta</label>
                <SelectPrioridad detallePrioridad={detalleEncuesta.prioridad} setPrioridadEncuesta={setPrioridadEncuesta} />
                {/*  <select className="form-control" id="prioridad" name="prioridad">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>*/}
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label htmlFor="aleatorio">Aleatoriedad </label>
                <input type="number" 
                id="aleatorio" name="aleatorio" className="form-control" min="1" max="100"
                 value={aleatorio_encuestaValue} 
                 onChange={handleChangeAleatorio}/>
              </div>
            </div>

          </div>

          <div className="row" style={{ marginBottom: 0 + 'px' }}>

            <div className="col-md-2" >
              <div className="form-group" id="tipoCliente" style={{ display: 'block' }}>
                <label htmlFor="tipoCliente">Tipo de Cliente</label>
                <TipoCliente detalleTipoCliente={detalleEncuesta.tipo_cliente} setTipo_cliente={setTipo_cliente} />
              </div>

            </div>

            {tipo_clienteValue === 'Clientes Afiliados' &&

              <>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Nivel que aplica</label>
                    <NivelCliente detalleTipoCliente={detalleEncuesta.clientes_nivel} setTipo_ClienteNivel={setTipo_ClienteNivel} />
                  </div>
                </div>
              </>

            }



            <div className="col-md-2" style={{ paddingTop: '0px !important' }}>
              <div className="form-group">
                <label htmlFor="beneficio" style={{ marginBottom: 0 + 'px' }}>
                  Aplica Beneficio
                </label>
                <br />
                <label style={{ marginRight: 10 + 'px' }}>
                  {' '}
                  <input type="radio" id="decision" name="decision" value="true" checked={status === 1} onClick={(e) => radioHandler(1)} /> SI{' '}
                </label>
                <label>
                  {' '}
                  <input type="radio" name="decision" id="decision" value="false" checked={status === 2} onClick={(e) => radioHandler(2)} /> NO{' '}
                </label>
              </div>
            </div>
          </div>

          {status === 1 &&

            <>
              <div className="row" id="beneficios" >

                <TipoBeneficio

                  detalleTipoBeneficio={detalleEncuesta.beneficios_encuesta[0]}
                  setTipo_beneficio={setTipo_beneficio}
                  tipo_clienteValue={tipo_clienteValue}
                />

{ /*
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Vigencia beneficio</label>
                    <DatePicker
                      selected={dateBeneficio}
                      onChange={(date) => setDateBeneficio(date)}
                      id="vigenciaBeneficio"
                      name="vigenciaBeneficio"
                      value={dateBeneficio}
                    />
                  </div>
                </div>
          */}

              </div>
            </>
          }
          {status === 2 && null}
          <div className="row" style={{ marginTop: 25 + 'px' }}>



            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="">Vigencia inicio</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleChangeFechainicio}
                  id="vigenciaEncuestaInicio"
                  name="vigenciaEncuestaInicio"
                  value={fechaInicio_encuestaValue}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="vigenciaEncuestafin">Vigencia fin</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleChangeFechaFin}
                  id="vigenciaEncuestaFin"
                  name="vigenciaEncuestaFin"
                  value={fechaFin_encuestaValue}
                />
              </div>
            </div>


            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="folios">Folios disponibles</label>
                <input type="number" name="folios" id="folios" 
                className="form-control" min="1" max="1000" 
                value={folios_encuestaValue}
                onChange={handleChangeFolios}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="vigenciaDias">Caducidad Folio "días"</label>
                <input
                  type="number"
                  id="vigenciaDias"
                  name="vigenciaDias"
                  className="form-control"
                  min="1"
                  max="100"
                  value={caducidadFolios_encuestaValue}
                  onChange={handleChangeCaducidadFolios}
                />
              </div>
            </div>

          </div>



          <div className="row" style={{ marginTop: 0 + 'px' }}>
            <div style={{ marginBottom: 30 + 'px', padding: '50px !important' }}>
              <SelectRegiones
                lRegiones={lRegiones}
                changeRegion={changeRegion}
                setSelecteRegion={setSelecteRegion}
                detalleRegion={detalleEncuesta.regiones}
              />

              <SelectSucursales
                lSucursales={filtroSucursales}
                changeSucursal={changeSucursal}
                setSelecteSucursal={setSelecteSucursal}
                detalleSucursal={detalleEncuesta.sucursales}
              />

              <SelectPlazas lPlazas={filtroPlazas} changePlaza={changePlaza} setSelectePlaza={setSelectePlaza}
                detallePlaza={detalleEncuesta.plazas} />
              <SelectDrives lDrives={filtroDrives} setSelectePuntoVenta={setSelectePuntoVenta}
                detalleDrive={detalleEncuesta.drives} />
            </div>
          </div>
          <div className="row" style={{ marginTop: 0 + 'px' }}>
            <SelectClasificacion
              lClasificacion={lClasificacion}
              setSelecteClasificacion={setSelecteClasificacion}
              changeClasificacion={changeClasificacion}
              detalleClasificacion={detalleEncuesta.clasificaciones}
            />
            <SelectTipoRecipe
              lTipoReceta={fTipoReceta}
              setSelecteTipoReceta={setSelecteTipoReceta}
              changeTipoReceta={changeTipoReceta}
              detalleTipoReceta={detalleEncuesta.tipos_receta}
            />
            <SelectTipoSabor
              lTipoSabor={fTipoSabor}
              setSelecteTipoSabor={setSelecteTipoSabor}
              changeTipoSabor={changeTipoSabor}
              detalleTipoSabor={detalleEncuesta.tipos_sabor}
            />
            <SelectTipoGrano
              lTipoGrano={fTipoGrano}
              setSelectTipoGrano={setSelectTipoGrano}
              changeTipoGrano={changeTipoGrano}
              detalleTipoGrano={detalleEncuesta.tipos_grano}
            />
            <SelectTipoLeche
              lTipoLeche={fTipoLeche}
              setSelecteTipoLeche={setSelecteTipoLeche}
              changeTipoLeche={changeTipoLeche}
              detalleTipoLeche={detalleEncuesta.tipos_leche}
            />

            <SelectPresentacion
              lPresentacion={fTipoPresentacion}
              setSelecteTipoPresentacion={setSelecteTipoPresentacion}
              changePresentacion={changePresentacion}
              onChange={handleChange}
              detallePresentaciones={detalleEncuesta.presentaciones}
            />

            <div className="col-md-12">
              <div className="form-group">
                <hr />
                <label htmlFor="tipoEncuesta">Productos</label>
                {
                  //llenar la tabla
                  /*<SelectProductos
                  lProductos={filtroProductos}*/
                }

                <fieldset>
                  <table id="tablaForm">
                    <thead>
                      <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Activo</th>
                      </tr>
                    </thead>

                    <tbody>{<TablaProductos lProductos={lProductos} currentItems={currentItems} />}</tbody>
                  </table>
                </fieldset>
                {
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="siguiente >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< anterior"
                    renderOnZeroPageCount={null}
                  />
                }
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 0 + 'px' }}>
            <hr />
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="iframe-add">Mensaje</label>
                <textarea
                  className="form-control"
                  id="mensaje"
                  name="mensaje"
                  row="10"
                  cols="50"
                  style={{ height: '100px' }}
                  spellCheck="false"
                  value={mensaje_encuestaValue}                  
                onChange={handleChangeMensaje}
                ></textarea>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="iframe-add">Script Formulario</label>
                <textarea
                  //defaultValue='(function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="text/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdy_2Bu7qT4z8QY5KUHAnzBh6_2FkNqryHvG7V3nZaDHS_2B_2Fwg.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");'
                  className="form-control"
                  id="iframe-add"
                  name="iframe-add"
                  row="10"
                  cols="50"
                  style={{ height: '100px' }}
                  spellCheck="false"
                  value={detalleEncuesta.script_encuesta}
                  value={script_encuestaValue} 
                onChange={handleChangeScript}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <button type="button" className="btn btn-primary" id="btnPregunta" onClick={() => updateForm(detalleEncuesta.encuesta_id)}>
                  Actualizar
                </button>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <button type="button" className="btn btn-success" id="btnView" onClick={seeForm}>
                  Visualizar Formulario
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>

      <div className="formulario-contenedor" id="formulario">
        {/*<FormContext.Provider value={{ handleChange }}>
                            <div className="App container">
                                <h3>{page_label}</h3>
                                <form>
                                    {fields ? fields.map((field, i) => <Element key={i} field={field} />) : null}
                                    <button type="submit" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Enviar</button>
                                </form>

                            </div>
                </FormContext.Provider>*/}
      </div>
    </>
  );
};

export default ModificaFormulario;
