import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const Sucursales = (props) => {
  const options = props.lSucursales.map((d) => ({
    'value': d.sucursal_id,
    'label': d.nombre,
  }));

  const changeSucursal = props.changeSucursal;
  const setSelecteSucursal = props.setSelecteSucursal;
  const detalleSucursal = props.detalleSucursal;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleSucursal != undefined) {
      const arr = detalleSucursal.map((d) => ({
        'value': d.sucursal_id,
        'label': d.sucursal.nombre,
        'cf_idSucursal': d.sucursal.cf_idSucursal,
      }));
  
      setValueDetalle(arr);
      setSelecteSucursal(arr);

      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    console.log(e);
    changeSucursal(e);
    setSelecteSucursal(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="sucursal">Sucursal</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="sucursal"
           value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))} 
            isDisabled={disable}
             />
        </div>
      </div>
    </>
  );
};

export default Sucursales;
