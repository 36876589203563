import logo from './logo.svg';
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Global, css } from '@emotion/react';
import Login from './login/Login';
import Home from './pages/Home';
import Entrevistas from './pages/Entrevistas';
import Folios from './pages/Folios';
import SingleFolio from './pages/SingleFolio';
import Agenda from './pages/Agenda';
import Formulario from './pages/Formulario';
import AddFormulario from './pages/Add-Formulario';

import clienteFolio from './pages/EncuestaFolio';

import Encuesta from './pages/Encuesta';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/formularios" component={Formulario} />

          <Route exact path="/addformulario" component={AddFormulario} />          
          <Route exact path="/cencuesta/:folio" component={clienteFolio} />
          <Route exact path="/encuesta" component={Encuesta} />
        </Switch>
      </Router>
      {/* <Login /> */}
    </>
  );
}

export default App;
