import React, { useState, useEffect } from 'react';
import Select from 'react-select';




const TipoBeneficio = (props) => {
    const [state, setState] = useState();
    const tipoBeneficioValue = props.setTipo_beneficio;
    const detalleTipoBeneficio = props.detalleTipoBeneficio;
    const [valueDetalle , setValueDetalle] = useState('');
    const tipo_clienteValue=props.tipo_clienteValue;
 
    const [valueCantidadDetalle , setValueCantidadDetalle] = useState('');
    const [valueDescuentoDetalle , setValueDescuentoDetalle] = useState('');

    const [actualizar, setActualizar] = useState(false);

    const [options,setOptions]=useState([]);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
      if (detalleTipoBeneficio != undefined) {
          setValueDetalle({value:detalleTipoBeneficio.tipo_beneficio,label:detalleTipoBeneficio.tipo_beneficio});
          tipoBeneficioValue(detalleTipoBeneficio.tipo_beneficio);
          if(detalleTipoBeneficio.tipo_beneficio == 'Monedero'){
            document.getElementById('monedero').style.display ='block';
            document.getElementById('descuento').style.display ='none';
    
          }else if(detalleTipoBeneficio.tipo_beneficio == 'Descuento'){
            document.getElementById('monedero').style.display ='none';
            document.getElementById('descuento').style.display ='block';
    
          }

          setValueCantidadDetalle(detalleTipoBeneficio.cantidad);
          setValueDescuentoDetalle(detalleTipoBeneficio.descuento); 
       
      }  
      if (props.disabled) {
        console.log("isDisable");
        setDisable(true);
      }
      const op = [ 
        { value: 'Monedero', label: 'Monedero' },
        { value: 'Descuento', label: 'Descuento' },
        { value: 'Promo QR', label: 'Promoción QR' }];
      setOptions(op);

    }, [actualizar]);

    useEffect(() => {      
      if (tipo_clienteValue=="Todos los Clientes") {
        console.log("tipoCliente if", tipo_clienteValue);
        const op = [{ value: 'Promo QR', label: 'Promoción QR' }];
        setOptions(op);
      }
      else{
        const op = [
          { value: 'Monedero', label: 'Monedero' },
          { value: 'Descuento', label: 'Descuento' },
          { value: 'Promo QR', label: 'Promoción QR' }
        ];
        setOptions(op);
      }
    }, [tipo_clienteValue]);

    const handleChange = (e) => {
        
       tipoBeneficioValue(e.value);
       setValueDetalle({value:e.value,label:e.value});

       if(e.value == 'Monedero'){
        document.getElementById('monedero').style.display ='block';
        document.getElementById('descuento').style.display ='none';

      }else if(e.value == 'Descuento'){
        document.getElementById('monedero').style.display ='none';
        document.getElementById('descuento').style.display ='block';

      }
      
      };

      const handleChangeCantidad = (e) => {
        
        setValueCantidadDetalle(e.value);
       };

       const handleChangeDescuento = (e) => {
        
        setValueDescuentoDetalle(e.value);
       };



  return (
    <>
     
      <div className="col-md-4">
              <div className="form-group">
              <label htmlFor="">Tipo de beneficio</label>
      <Select options={options} id="tipoBeneficio" 
      name="tipoBeneficio" onChange={handleChange}
      value={valueDetalle}
      isDisabled={disable}
      
      />
      </div>
            </div>

      {/*monedero*/}
      <div className="col-md-2" id="monedero" style={{ display: "none"}}>
      <div className="form-group">
        <label htmlFor="">Cantidad</label>
        <input type="number" id="cantBeneficio" name="cantBeneficio" className="form-control" min="1" max="100"
        value={valueCantidadDetalle}
        onChange={handleChangeCantidad}
        disabled={disable}
        />
      </div>
      
    </div>
     
      <div className="col-md-2" id="descuento" style={{ display: "none"}}>
      <div className="form-group">
        <label htmlFor="">Descuento</label>
        <input type="number" id="descBeneficio" name="descBeneficio" className="form-control" min="1" max="100" 
        value={valueDescuentoDetalle}
        onChange={handleChangeDescuento}
        disabled={disable}
        />
      </div>
    </div>
   
      
      

      
    </>
  );
};

export default TipoBeneficio;
