import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const TipoGrano = (props) => {
  const options = props.lTipoGrano.map((d) => ({
    'value': d.tipoGrano_id,
    'label': d.descripcion,
    'cf_GrainTypeId': d.cf_GrainTypeId,
  }));

  const changeTipoGrano = props.changeTipoGrano;
  const setSelectTipoGrano = props.setSelectTipoGrano;
  const detalleTipoGrano = props.detalleTipoGrano;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleTipoGrano != undefined) {
      const arr = detalleTipoGrano.map((d) => ({
        'value': d.tipoGrano_id,
        'label': d.tipo_grano.descripcion,
        'cf_GrainTypeId': d.tipo_grano.cf_GrainTypeId,
      }));
  
      setValueDetalle(arr);
      setSelectTipoGrano(arr);
      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    changeTipoGrano(e);
    setSelectTipoGrano(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="tipoEncuesta">Tipo de Grano</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="tipoGrano" 
          value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))}
            
            isDisabled={disable}
            
          />
        </div>
      </div>
    </>
  );
};

export default TipoGrano;
