import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const Clasificacion = (props) => {
  const options = props.lClasificacion.map((d) => ({
    'value': d.clasificacion_id,
    'label': d.nombre,
    'cf_clasificacionId': d.cf_ClassificationId,
  }));
  const changeClasificacion = props.changeClasificacion;
  const setSelecteClasificacion = props.setSelecteClasificacion;
  const detalleClasificacion = props.detalleClasificacion;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    //console.log("setSelecteClasificacion",setSelecteClasificacion);
    if (detalleClasificacion != undefined) {
      const arr = detalleClasificacion.map((d) => ({
        'value': d.clasificacion_id,
        'label': d.clasificacion.nombre,
        'cf_clasificacionId': d.clasificacion.cf_ClassificationId,
      }));
  
      setValueDetalle(arr);
      setSelecteClasificacion(arr);
      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);
  

  const onchangeOptions = (e) => {

    changeClasificacion(e);
    setSelecteClasificacion(e);
    setValueDetalle(e);

  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="clasificacion">Clasificación</label>
          <Select options={options} isMulti={true} onChange={onchangeOptions} id="clasificacion"
           value={
            valueDetalle.map((a)=>({
              value:a.value,
              label:a.label
             }))}
             isDisabled={disable} 
          />
        </div>
      </div>
    </>
  );
};

export default Clasificacion;
