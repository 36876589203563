import React from 'react';
import axios from 'axios';

export default class Axios extends React.Component {
  //   url = 'https://jsonplaceholder.typicode.com/users';

  state = {
    persons: [],
  };

  componentDidMount() {
    axios.get('https://jsonplaceholder.typicode.com/users').then((res) => {
      const persons = res.data;
      this.setState({ persons });
    });
  }

  render() {
    const { mensaje } = this.props;
    return (
      <>
        <div>este es el mensaje {mensaje}</div>
        <select className="form-control" id="tipoEncuesta" name="tipoEncuesta">
          {this.state.persons.map((person) => (
            <option key={person.id}>{person.name}</option>
          ))}
        </select>
      </>
    );
  }
}
