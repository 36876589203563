import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clienteAxios from '../../Utils/axios';
import { Decrypt } from '../../Utils/EncryptDecrypt';

const TipoSabor = (props) => {
  const options = props.lTipoSabor.map((d) => ({
    'value': d.tipoSabor_id,
    'label': d.nombre,
    'cf_FlavorTypeId': d.cf_FlavorTypeId,
  }));

  const changeTipoSabor = props.changeTipoSabor;
  const setSelecteTipoSabor = props.setSelecteTipoSabor;
  const detalleTipoSabor = props.detalleTipoSabor;
  const [valueDetalle , setValueDetalle] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (detalleTipoSabor != undefined) {
      const arr = detalleTipoSabor.map((d) => ({
        'value': d.tipoSabor_id,
        'label': d.tipo_sabor.nombre,
        'cf_FlavorTypeId': d.tipo_sabor.cf_FlavorTypeId,
      }));
  
      setValueDetalle(arr);
      setSelecteTipoSabor(arr);
      if (props.disabled) {
        setDisable(true);
      }
    }  
  }, [actualizar]);

  const onchangeOptions = (e) => {
    console.log(e);
    changeTipoSabor(e);
    setSelecteTipoSabor(e);
    setValueDetalle(e);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="tipoEncuesta">Tipo de Sabor</label>
          <Select options={options} isMulti onChange={onchangeOptions} id="tipoSabor"
           value={
            valueDetalle.map((a)=>({
             value:a.value,
             label:a.label
            }))}
            isDisabled={disable}
            
         />
        </div>
      </div>
    </>
  );
};

export default TipoSabor;
